import React, { useCallback, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { Link } from "gatsby-plugin-intl";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Checkbox from "./inputs/checkbox";

const confirmButtonId = "rcc-confirm-button";
const analyticsCookieNames = [
  "gatsby-gdpr-google-analytics",
  "gatsby-gdpr-hotjar",
];

export default function CookieBar() {
  const cookieConsentRef = useRef<CookieConsent>(null);
  const [analyticsCookies, setAnalyticsCookies] = useState(true);
  const location = useLocation();

  const handleAnalyticsCookiesChange = useCallback(() => {
    setAnalyticsCookies((previous) => !previous);
  }, []);

  const handleButtonClick = (id: string) => {
    if (id === confirmButtonId && analyticsCookies) {
      // @ts-ignore
      cookieConsentRef?.current?.accept();
      analyticsCookieNames.forEach((cookieName) => {
        Cookies.set(cookieName, true);
      });
      initializeAndTrack(location);
    } else {
      // @ts-ignore
      cookieConsentRef?.current?.decline();
    }
  };

  return (
    <CookieConsent
      ref={cookieConsentRef}
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      style={{
        background: "#60606FEE",
        alignItems: "center",
        justifyContent: "center",
      }}
      contentClasses="container"
      buttonClasses="bg-white text-black font-500 py-2 px-4 rounded leading-none"
      declineButtonClasses="bg-red-bright text-white font-500 py-2 px-4 rounded leading-none mr-4"
      buttonWrapperClasses="px-4 pb-4 lg:pt-4"
      enableDeclineButton
      ButtonComponent={({
        "aria-label": ariaLabel,
        children,
        className,
        id,
        ...rest
      }) => {
        return (
          <button
            aria-label={ariaLabel}
            className={className}
            onClick={() => {
              handleButtonClick(id);
            }}
          >
            <span className="block pt-px">{children}</span>
          </button>
        );
      }}
    >
      <h6 className="pb-2 text-lg font-light">This site uses cookies!</h6>
      {/* TODO: MFE pls textacie */}
      <p className="leading-tight pb-2 text-white opacity-70 font-light">
        We use cookies to ensure you the best experience. By using our website
        you agree to our{" "}
        <Link to="/privacy-policy" className="underline hover:no-underline">
          Privacy Policy
        </Link>
        .
      </p>
      {/* TODO: MFE pls styly */}
      <Checkbox checked={true} disabled id="necessary" label="Necessary" />
      <span className="mr-4" />
      <Checkbox
        checked={analyticsCookies}
        id="analytics"
        label="Analytics"
        onChange={handleAnalyticsCookiesChange}
      />
    </CookieConsent>
  );
}
