import React, { useState, useCallback } from "react";
import { useIntl, Link, changeLocale } from "gatsby-plugin-intl";
import { useLocation } from "@reach/router";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import YounicsLogo from "../images/younics_logo.inline.svg";
import { matchPage } from "../helpers";

const NavBar = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const openMenu = useCallback(() => {
    document.body.classList.add("overflow-hidden");
    setOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    document.body.classList.remove("overflow-hidden");
    setOpen(false);
  }, []);

  const isProjectsPage = matchPage("projects", pathname);
  const isCareerPage = matchPage("career", pathname);

  const LocaleSwitch = useCallback(
    () => (
      <div className="md:pl-6 lg:pl-4 xl:pl-6">
        <button
          className="px-4 py-4 uppercase transition-colors focus:outline-none text-gray-mid hover:text-white"
          aria-label={`Change Locale ${intl.locale === "en" ? "SK" : "EN"}`}
          onClick={() => {
            closeMenu();
            changeLocale(intl.locale === "en" ? "sk" : "en");
          }}
        >
          {intl.locale === "en" ? "sk" : "en"}
        </button>
      </div>
    ),
    []
  );

  return (
    <nav className={`${open ? "fixed" : "absolute"} top-0 right-0 left-0 z-30`}>
      <div className="relative z-30 container py-10">
        <div className="relative flex items-center justify-between">
          <div className="flex-1 flex items-center justify-between">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" onClick={closeMenu}>
                <YounicsLogo className="text-white fill-current hover:text-black transition-colors duration-300" />
              </Link>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div
                className={`flex space-x-4 font-500 ${
                  pathname === `/${intl.locale}/`
                    ? "text-white"
                    : "text-gray-mid"
                }`}
              >
                <Link
                  to="/projects"
                  activeClassName="text-white"
                  className={`px-4 py-4 transition-colors bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue ${
                    isProjectsPage ? "text-white" : ""
                  }`}
                >
                  {intl.formatMessage({ id: "navbar.projects" })}
                </Link>
                <Link
                  to="/career"
                  activeClassName="text-white"
                  className={`px-4 py-4 transition-colors bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue ${
                    isCareerPage ? "text-white" : ""
                  }`}
                >
                  {intl.formatMessage({ id: "navbar.career" })}
                </Link>
                <AnchorLink
                  to={`${pathname}#contact`}
                  className="px-4 py-4 transition-colors bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue"
                >
                  {intl.formatMessage({ id: "navbar.contact" })}
                </AnchorLink>
                {/* <LocaleSwitch /> */}
              </div>
            </div>
          </div>
          <div className="sm:hidden -my-4">
            {/* <!-- Mobile menu button--> */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
              aria-label={`${!open ? "Open" : "Close"} Menu`}
              onClick={!open ? openMenu : closeMenu}
            >
              <span className="sr-only">
                {open
                  ? intl.formatMessage({ id: "navbar.close_main_menu" })
                  : intl.formatMessage({ id: "navbar.open_main_menu" })}
              </span>
              {open ? (
                <svg
                  className="block h-10 w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-10 w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, toggle classes based on menu state. */}
      <div
        className={`${
          open ? "right-0" : "-right-full"
        } md:hidden fixed z-10 top-0 w-screen h-screen transition-all duration-500 bg-gray-darkest overflow-auto`}
      >
        <div className="pt-24 flex flex-col items-center justify-center text-2xl text-gray-mid ">
          <Link
            to="/"
            onClick={closeMenu}
            activeClassName="text-white"
            className="px-4 mx-4 py-2 my-2 transition-colors bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue"
          >
            {intl.formatMessage({ id: "navbar.home" })}
          </Link>
          <Link
            to="/projects"
            onClick={closeMenu}
            activeClassName="text-white"
            className={`px-4 mx-4 py-2 my-2 transition-colors bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue ${
              isProjectsPage ? "text-white" : ""
            }`}
          >
            {intl.formatMessage({ id: "navbar.projects" })}
          </Link>
          <Link
            to="/career"
            onClick={closeMenu}
            activeClassName="text-white"
            className={`px-4 mx-4 py-2 my-2 transition-colors bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue ${
              isCareerPage ? "text-white" : ""
            }`}
          >
            {intl.formatMessage({ id: "navbar.career" })}
          </Link>
          <AnchorLink
            to={`${pathname}#contact`}
            onAnchorLinkClick={closeMenu}
            className="px-4 mx-4 py-2 my-2 transition-colors bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue"
          >
            {intl.formatMessage({ id: "navbar.contact" })}
          </AnchorLink>
          {/* <LocaleSwitch /> */}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
