import React, { useState, useCallback, useRef } from "react";
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl";
import Bugsnag from "@bugsnag/js";
import Axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import Reaptcha from "reaptcha";
import LabeledInput from "./labeledInput";
import LabeledTextarea from "./labeledTextarea";
import { MAIL_FUNCTION_URL } from "../constants";

export default function Contact() {
  const intl = useIntl();
  const [error, setError] = useState<boolean | null>(null);
  const recaptcha = useRef(null);

  const renderResult = useCallback(() => {
    if (error === true) {
      return (
        <div className="text-red-bright font-600 text-center mt-2 mb-6 text-xl md:text-2xl">
          <FormattedMessage
            id="contact.submit_error_message"
            defaultMessage="Your message could not be sent.{linebreak}Please try again."
            values={{
              linebreak: <br />,
            }}
          />
        </div>
      );
    } else if (error === false) {
      return (
        <div className="text-white font-600 text-center mt-2 mb-6 text-xl md:text-2xl">
          <FormattedMessage
            id="contact.submit_success_message"
            defaultMessage="Thank you for your message.{linebreak}You will hear from us soon."
            values={{
              linebreak: <br />,
            }}
          />
        </div>
      );
    }
  }, [error]);

  const executeCaptcha = useCallback(() => {
    recaptcha.current.execute();
  }, [recaptcha]);

  return (
    <section id="contact" className="container my-20 md:my-24 lg:my-28">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-center leading-none pb-8">
          {intl.formatMessage({ id: "contact.title" })}
        </h1>
        <p className="text-lg text-gray-purple text-center max-w-lg mx-auto pb-20">
          {intl.formatMessage({ id: "contact.subtitle" })}
        </p>

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          }}
          validationSchema={yup.object().shape({
            firstName: yup.string().required(
              intl.formatMessage({
                id: "contact.first_name_required_error",
              })
            ),
            lastName: yup.string().required(
              intl.formatMessage({
                id: "contact.last_name_required_error",
              })
            ),
            email: yup
              .string()
              .email(
                intl.formatMessage({
                  id: "contact.email_invalid_error",
                })
              )
              .required(
                intl.formatMessage({
                  id: "contact.email_required_error",
                })
              ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const {
              firstName,
              lastName,
              email,
              phone,
              company,
              message,
            } = values;

            Axios.post(MAIL_FUNCTION_URL, {
              firstName,
              lastName,
              email,
              phone,
              company,
              message,
            })
              .then(() => {
                setError(false);
                setSubmitting(false);
                resetForm();
              })
              .catch((error) => {
                // console.error(error);
                setError(true);
                setSubmitting(false);
                Bugsnag.notify(error);
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldTouched,
              isValid,
            } = props;
            const {
              firstName,
              lastName,
              email,
              phone,
              company,
              message,
            } = values;
            return (
              <form
                className="max-w-xl mx-auto"
                onSubmit={(e) => {
                  e.preventDefault();
                  executeCaptcha();
                }}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <LabeledInput
                    required
                    id="firstName"
                    label={intl.formatMessage({ id: "contact.first_mame" })}
                    error={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : undefined
                    }
                    type="text"
                    value={firstName}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("firstName", true)}
                  />
                  <LabeledInput
                    required
                    id="lastName"
                    label={intl.formatMessage({ id: "contact.last_name" })}
                    error={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : undefined
                    }
                    type="text"
                    value={lastName}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("lastName", true)}
                  />
                </div>
                <LabeledInput
                  required
                  id="email"
                  label={intl.formatMessage({ id: "contact.email" })}
                  error={
                    errors.email && touched.email ? errors.email : undefined
                  }
                  type="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("email", true)}
                />
                <LabeledInput
                  id="phone"
                  label={intl.formatMessage({ id: "contact.phone_number" })}
                  error={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                  type="text"
                  value={phone}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("phone", true)}
                />
                <LabeledInput
                  id="company"
                  label={intl.formatMessage({ id: "contact.company" })}
                  type="text"
                  value={company}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("company", true)}
                />
                <LabeledTextarea
                  id="message"
                  label={intl.formatMessage({ id: "contact.message" })}
                  rows={3}
                  value={message}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("message", true)}
                />
                <Reaptcha
                  className={
                    (touched.firstName && !errors.firstName) ||
                    (touched.lastName && !errors.lastName) ||
                    (touched.email && !errors.email)
                      ? "g-recaptcha"
                      : "hidden"
                  }
                  ref={recaptcha}
                  sitekey="6Led89oUAAAAACu22aD-rJAcaOKRqHE71zcIPpIH"
                  onVerify={handleSubmit}
                  size="invisible"
                />
                {error !== null ? renderResult() : null}
                <button
                  className="btn bg-blue w-full focus:outline-none border-2 border-transparent focus:border-white"
                  type="submit"
                  aria-label="Submit Contact Form"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? intl.formatMessage({ id: "contact.sending" })
                    : intl.formatMessage({ id: "contact.sent" })}
                </button>
                <p className="text-sm text-gray-mid pt-4 leading-snug px-2">
                  We value your privacy — by submitting the form, you agree that
                  we can process your personal data as described in our{" "}
                  <Link
                    to="/privacy-policy"
                    className="text-pink hover:text-white "
                  >
                    Privacy policy
                  </Link>
                  .
                </p>
              </form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
}
