import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
}

export default function Checkbox({ id, label, ...rest }: Props) {
  return (
    <>
      <input type="checkbox" id={id} name={id} {...rest} />
      <label htmlFor={id}> {label}</label>
    </>
  );
}
