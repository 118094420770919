import React, { useCallback, useState } from "react";

interface LabeledFileInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | React.ReactNode;
  file?: File | null;
  hint?: string | React.ReactNode;
  error?: string;
}

export default function LabeledFileInput({
  id,
  label,
  error,
  file,
  hint,
  ...rest
}: LabeledFileInputProps) {
  const [hintVisible, setHintVisible] = useState<boolean>(false);
  const handleHintToggle = useCallback(() => {
    setHintVisible((hintVisible) => !hintVisible);
  }, []);

  return (
    <div className="mb-4">
      <div className="overflow-hidden relative group">
        <button
          type="button"
          className={`input text-left text-purple-light group-hover:text-blue group-hover:border-blue group-hover:bg-white-a10 group-hover:border-opacity-80 transform transition-colors duration-500 relative z-10 pr-16 ${
            error ? "border-red-bright border-opacity-50" : ""
          }`}
          aria-label="Click to select an attachemnt"
        >
          {file ? (
            <>
              <span className="text-white">{file?.name}</span>
              <span className="pl-1"> Change</span>
            </>
          ) : (
            <label htmlFor={id} className="text-sm ">
              {label}
            </label>
          )}
        </button>
        <input
          className={`input cursor-pointer z-20 absolute block opacity-0 w-full top-0 bottom-0 left-0`}
          id={id}
          type="file"
          {...rest}
        />
        {hint ? (
          <button
            className="text-xs z-30 absolute text-gray-mid p-1 leading-none hover:text-white right-5 top-1/2 focus:outline-none transform -translate-y-1/2"
            onClick={handleHintToggle}
            type="button"
            aria-label={`${!hintVisible ? "Show" : "Hide"} attachment hint`}
          >
            <span className="block pt-px">Hint</span>
          </button>
        ) : null}
      </div>
      {error && <div className="text-red-bright text-sm pt-1">{error}</div>}
      {hintVisible && (
        <div className="text-sm pb-1 pt-2 text-gray-mid">{hint}</div>
      )}
    </div>
  );
}
