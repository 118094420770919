import React, { ReactChild } from "react";

interface LabeledInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactChild;
  error?: string;
}

export default function LabeledInput({
  id,
  label,
  error,
  value,
  required,
  ...rest
}: LabeledInputProps) {
  return (
    <div className="mb-4">
      <div className="relative">
        {!value && (
          <label htmlFor={id} className="absolute top-6 pl-7 text-sm">
            {label}
            {required ? "*" : ""}
          </label>
        )}
        <input
          className={`input ${
            error ? "border-red-bright border-opacity-50" : ""
          }`}
          id={id}
          value={value}
          {...rest}
        />
      </div>
      {error && <div className="text-red-bright text-sm pt-1">{error}</div>}
    </div>
  );
}
