import React, { ReactNode } from "react";

interface LabeledTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string | ReactNode;
  error?: string;
}

export default function LabeledTextarea({
  id,
  label,
  error,
  value,
  required,
  ...rest
}: LabeledTextareaProps) {
  return (
    <div className="mb-4">
      <div className="relative">
        {!value && (
          <label
            htmlFor={id}
            className={`absolute top-6 pl-7 text-sm ${
              required ? "text-white" : "text-gray-mid"
            }`}
          >
            {label}
            {required ? "*" : " (Optional)"}
          </label>
        )}
        <textarea
          className={`input ${
            error ? "border-red-bright border-opacity-50" : ""
          }`}
          id={id}
          value={value}
          {...rest}
        />
      </div>
      {error && <div className="text-red-bright text-sm -mt-1">{error}</div>}
    </div>
  );
}
