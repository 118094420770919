export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader?.result === "string") {
        resolve(reader.result.split(",")[1]);
      } else {
        reject("Couldn't parse reader result.");
      }
    };
    reader.onerror = (error) => reject(error);
  });
}

// Matchne /en/career aj /en/career/whatever
export function matchPageAndOptionalSubpages(page: string, pathname: string) {
  return new RegExp(`^/((.+)/)?(${page})+/?(.*)$`).test(pathname);
}

// Matchne /en/career/whatever
export function matchSubpages(page: string, pathname: string) {
  return new RegExp(`^/[^/]+/(${page})+/[^/]+/?$`).test(pathname);
}

// Matchne /en/career
export function matchPage(page: string, pathname: string) {
  return new RegExp(`^/[^/]+/(${page})+/?$`).test(pathname);
}
