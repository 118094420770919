import React, { useState, useCallback, useRef } from "react";
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl";
import Bugsnag from "@bugsnag/js";
import Axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import Reaptcha from "reaptcha";
import LabeledInput from "./labeledInput";
import LabeledFileInput from "./labeledFileInput";
import LabeledTextarea from "./labeledTextarea";
import { fileToBase64 } from "../helpers";
import {
  MAIL_FUNCTION_URL,
  MAX_FILE_SIZE_IN_MB,
  SUPPORTED_FILE_FORMATS,
} from "../constants";

export default function Contact() {
  const intl = useIntl();
  const [error, setError] = useState<boolean | null>(null);
  const recaptcha = useRef<Reaptcha>(null);

  const renderResult = useCallback(() => {
    if (error === true) {
      return (
        <div className="text-red-bright font-600 text-center mt-2 mb-6 text-xl md:text-2xl">
          <FormattedMessage
            id="contact.submit_error_message"
            defaultMessage="Your message could not be sent.{linebreak}Please try again."
            values={{
              linebreak: <br />,
            }}
          />
        </div>
      );
    } else if (error === false) {
      return (
        <div className="text-white font-600 text-center mt-2 mb-6 text-xl md:text-2xl">
          <FormattedMessage
            id="contact.submit_success_message"
            defaultMessage="Thank you for your message.{linebreak}We'll get in touch with you soon."
            values={{
              linebreak: <br />,
            }}
          />
        </div>
      );
    }
  }, [error]);

  const executeCaptcha = useCallback(() => {
    if (recaptcha.current) {
      recaptcha.current.execute();
    }
  }, [recaptcha]);

  return (
    <section id="contact" className="container my-20 md:my-24 lg:my-28">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-center leading-none pb-8">
          {intl.formatMessage({ id: "contact.title_for_job" })}
        </h1>
        <Formik<{
          firstName: string;
          lastName: string;
          email: string;
          message: string;
          attachment: File | null;
        }>
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            attachment: null,
          }}
          validationSchema={yup.object().shape({
            firstName: yup.string().required(
              intl.formatMessage({
                id: "contact.first_name_required_error",
              })
            ),
            lastName: yup.string().required(
              intl.formatMessage({
                id: "contact.last_name_required_error",
              })
            ),
            email: yup
              .string()
              .email(
                intl.formatMessage({
                  id: "contact.email_invalid_error",
                })
              )
              .required(
                intl.formatMessage({
                  id: "contact.email_required_error",
                })
              ),
            attachment: yup
              .mixed()
              .required("Attachment is required.")
              .test(
                "respects-max-file-size",
                intl.formatMessage({
                  id: "contact.attachment_max_file_size_error",
                }),
                (value) => {
                  return (
                    value?.size &&
                    value.size <= MAX_FILE_SIZE_IN_MB * 1024 * 1024
                  );
                }
              )
              .test(
                "respects-file-format",
                intl.formatMessage({
                  id: "contact.attachment_file_format_error",
                }),
                (value) => {
                  return (
                    value?.type && SUPPORTED_FILE_FORMATS.includes(value.type)
                  );
                }
              ),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            const { firstName, lastName, email, attachment, message } = values;

            const mailPayload: Omit<typeof values, "attachment"> & {
              attachment?: {
                filename: string;
                contentType: string;
                content: string;
                encoding: "base64";
              };
              source: "job-form";
            } = {
              firstName,
              lastName,
              email,
              message,
              source: "job-form",
            };

            if (attachment) {
              try {
                // NOTE: zatial staci na tych par typov
                const extension = attachment.type.split("/")[1];

                mailPayload.attachment = {
                  filename: `${firstName} ${lastName} - CV.${extension}`,
                  contentType: attachment.type,
                  content: await fileToBase64(attachment),
                  encoding: "base64",
                };
              } catch (error) {
                setSubmitting(false);
                setFieldError(
                  "attachment",
                  intl.formatMessage({
                    id: "contact.attachment_encoding_error",
                  })
                );
                Bugsnag.notify(error);
                return;
              }
            } else {
              setSubmitting(false);
              setFieldError(
                "attachment",
                intl.formatMessage({
                  id: "contact.attachment_unknown_error",
                })
              );
              return;
            }

            try {
              await Axios.post(MAIL_FUNCTION_URL, mailPayload);
              setError(false);
              setSubmitting(false);
              resetForm();
            } catch (error) {
              // console.error(error);
              setError(true);
              setSubmitting(false);
              Bugsnag.notify(error);
            }
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              setFieldError,
            } = props;
            const { firstName, lastName, email, message, attachment } = values;
            return (
              <form
                className="max-w-xl mx-auto"
                onSubmit={(e) => {
                  e.preventDefault();
                  executeCaptcha();
                }}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <LabeledInput
                    required
                    id="firstName"
                    label={intl.formatMessage({ id: "contact.first_mame" })}
                    error={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : undefined
                    }
                    type="text"
                    value={firstName}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("firstName", true)}
                  />
                  <LabeledInput
                    required
                    id="lastName"
                    label={intl.formatMessage({ id: "contact.last_name" })}
                    error={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : undefined
                    }
                    type="text"
                    value={lastName}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("lastName", true)}
                  />
                </div>
                <LabeledInput
                  required
                  id="email"
                  label={intl.formatMessage({ id: "contact.email" })}
                  error={
                    errors.email && touched.email ? errors.email : undefined
                  }
                  type="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("email", true)}
                />
                <LabeledFileInput
                  id="attachment"
                  label="Upload CV"
                  file={attachment}
                  error={
                    errors.attachment && touched.attachment
                      ? errors.attachment
                      : undefined
                  }
                  onChange={(event) => {
                    setFieldTouched("attachment", true);
                    if (event?.target?.files) {
                      setFieldValue("attachment", event?.target?.files[0]);
                    }
                  }}
                  hint={`Maximum file size is ${MAX_FILE_SIZE_IN_MB}MB and the supported formats are ${SUPPORTED_FILE_FORMATS.map(
                    (format) => format.split("/")[1]
                  ).join(", ")}.`}
                />
                <LabeledTextarea
                  id="message"
                  label={intl.formatMessage({ id: "contact.message" })}
                  rows={3}
                  value={message}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("message", true)}
                />
                <Reaptcha
                  className={
                    (touched.firstName && !errors.firstName) ||
                    (touched.lastName && !errors.lastName) ||
                    (touched.email && !errors.email)
                      ? "g-recaptcha"
                      : "hidden"
                  }
                  ref={recaptcha}
                  sitekey="6Led89oUAAAAACu22aD-rJAcaOKRqHE71zcIPpIH"
                  onVerify={handleSubmit}
                  size="invisible"
                />
                {error !== null ? renderResult() : null}
                <button
                  className="btn bg-blue w-full focus:outline-none border-2 border-transparent focus:border-white"
                  type="submit"
                  aria-label="Submit Contact Form"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? intl.formatMessage({ id: "contact.sending" })
                    : intl.formatMessage({ id: "contact.sent" })}
                </button>
                <p className="text-sm text-gray-mid pt-4 leading-snug px-2">
                  We value your privacy — by submitting the form, you agree that
                  we can process your personal data as described in our{" "}
                  <Link
                    to="/privacy-policy"
                    className="text-pink hover:text-white "
                  >
                    Privacy policy
                  </Link>
                  .
                </p>
              </form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
}
