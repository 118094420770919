import React, { useMemo } from "react";
import { useLocation } from "@reach/router";
import CookieBar from "./cookieBar";
import Footer from "./footer";
import NavBar from "./navbar";
import Contact from "./contact";
import ContactForJob from "./contactForJob";
import { matchPageAndOptionalSubpages } from "../helpers";

export interface LayoutProps {
  children: any;
  hideContactForm?: boolean;
}

export default function Layout({
  children,
  hideContactForm = false,
}: LayoutProps) {
  const { pathname } = useLocation();

  const contactForm = useMemo(() => {
    if (hideContactForm) return null;

    const isCareerPage = matchPageAndOptionalSubpages("career", pathname);

    return isCareerPage ? <ContactForJob /> : <Contact />;
  }, [hideContactForm, pathname]);

  return (
    <div className="min-h-screen flex flex-col overflow-hidden w-full relative">
      <NavBar />
      <div className="relative overflow-hidden z-20">
        <div className="absolute z-0 h-108 md:h-200 w-200 md:w-400 -left-72 -top-40 filter-blur bg-gradient-radial-to-br-from-blue-to-black" />
        <div className="absolute z-0 -bottom-32 md:-bottom-60 left-0 right-0 h-108 md:h-200 blend-lighten bg-gradient-radial-to-b-from-black-to-transparent">
          <div className="absolute top-0 left-0 right-0 h-108 bg-gradient-to-b from-black to-black-a0" />
        </div>
        <div className="relative z-20">
          <main className="flex-1 min-h-64">{children}</main>
          {contactForm}
        </div>
      </div>
      <Footer />
      <CookieBar />
    </div>
  );
}
