import React, { useCallback, useState } from "react";
import { Link, useIntl } from "gatsby-plugin-intl";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useLocation } from "@reach/router";
import YounicsLogo from "../images/younics_logo.inline.svg";

const CONTACT_MAIL = "hello@younics.com";

export default function Footer() {
  const { pathname } = useLocation();
  const intl = useIntl();
  const [emailCopied, setEmailCopied] = useState(false);

  const copyEmailToClipboard = useCallback(() => {
    const el = document.createElement("textarea");
    el.value = CONTACT_MAIL;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setEmailCopied(true);
    setTimeout(() => {
      setEmailCopied(false);
    }, 1000);
  }, []);

  return (
    <footer className="bg-white text-black pt-12 py-6 lg:py-12 xl:py-16 relative z-10">
      <div className="container">
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <YounicsLogo className="text-black fill-current hover:text-red-bright transition-colors duration-300" />
          </Link>
        </div>
        <div className="py-12 xl:py-16 flex flex-wrap">
          <div className="w-full md:w-1/4 pb-12 md:pb-0 flex flex-col items-center  md:items-start">
            <Link
              to="/"
              className="bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue"
            >
              {intl.formatMessage({ id: "navbar.home" })}
            </Link>
            <Link
              to="/projects"
              className="bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue"
            >
              {intl.formatMessage({ id: "navbar.projects" })}
            </Link>
            <Link
              to="/career"
              className="bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue"
            >
              {intl.formatMessage({ id: "navbar.career" })}
            </Link>
            <AnchorLink
              to={`${pathname}#contact`}
              className="bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue"
            >
              {intl.formatMessage({ id: "navbar.contact" })}
            </AnchorLink>
            <Link
              to="/privacy-policy"
              className="bg-clip-text hover:text-transparent bg-gradient-to-r from-purple to-blue whitespace-nowrap"
            >
              {intl.formatMessage({ id: "privacy_policy" })}
            </Link>
          </div>
          <div className="w-full md:w-2/4 pb-12 md:pb-0">
            <address className="flex flex-wrap justify-center md:justify-start">
              <p className="w-full md:w-auto md:pr-16 xl:pr-24 pb-4 lg:pb-0 text-center md:text-left">
                <span className="font-bold">Younics s.r.o.</span>
                <br />
                Ružová dolina 6<br />
                821 08 Bratislava
                <br />
                Slovakia
              </p>
              <p className="w-full md:w-auto text-center md:text-left">
                {intl.formatMessage({ id: "ico" })}: 51711559
                <br />
                {intl.formatMessage({ id: "dic" })}: 2120766890
                <br />
                {intl.formatMessage({ id: "ic_dph" })}: SK2120766890
              </p>
            </address>
          </div>
          <div className="w-full md:w-1/4 flex flex-col items-center md:items-end">
            <div className="flex flex-col items-center md:items-start">
              <div className="pb-3">
                <button
                  className="font-bold focus:outline-none"
                  onClick={copyEmailToClipboard}
                  aria-label="Copy contact email to clipboard"
                >
                  {emailCopied ? "Email copied!" : CONTACT_MAIL}
                </button>
              </div>
              <AnchorLink
                to={`${pathname}#contact`}
                className="text-center btn bg-gradient-to-r from-purple to-blue w-52 md:w-44 lg:w-52"
              >
                {intl.formatMessage({ id: "get_in_touch" })}
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className="text-gray-footer-text text-sm flex flex-wrap lg:flex-nowrap">
          <p className="md:w-1/4 pr-4 pb-2 md:pb-0">
            <span className="whitespace-nowrap">
              © 2018 - {new Date().getFullYear()}
            </span>{" "}
            <span className="whitespace-nowrap">Younics s.r.o.</span>
          </p>
          <p className="md:w-3/4 order-2">
            Obchodný register Okresného súdu Bratislava I, oddiel: Sro, vložka
            č. 128558/B
          </p>
        </div>
      </div>
    </footer>
  );
}
