import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import ChevronRightIcon from "./images/icons/chevron_right.inline.svg";
import CaretRightIcon from "./images/icons/caret_right.inline.svg";
import CaretDownIcon from "./images/icons/caret_down.inline.svg";

export const MAX_FILE_SIZE_IN_MB = 5;
export const SUPPORTED_FILE_FORMATS = [
  "application/pdf",
  "image/jpeg",
  "image/png",
];

export const MAIL_FUNCTION_URL =
  "https://younics.com/.netlify/functions/mail-submit";

export const RICH_TEXT_RENDER_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-600">{text}</span>,
  },
  renderText: (text) =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="text-4xl md:text-5xl font-600 pb-6">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="text-3xl md:text-4xl font-600 pb-5">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-2xl md:text-3xl font-600 pb-4 ">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="text-xl md:text-2xl font-600 pb-3">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="text-lg md:text-xl pb-2">{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="md:text-lg pb-1">{children}</h6>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // HOTFIX: contentful returns empty paragraphs
      let allChildrenEmpty = true;
      node.content.forEach((child) => {
        if (child.value) {
          allChildrenEmpty = false;
        }
      });
      return !allChildrenEmpty ? (
        <p className="pb-4 xl:text-lg">{children}</p>
      ) : null;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="flex pb-1 md:pb-2 lg:pb-3 last:pb-0 max-w-md">
        <ChevronRightIcon className="flex-shrink-0 text-gray-mid fill-current mt-0.5" />
        <span className="pl-4">{children}</span>
      </li>
    ),
    [BLOCKS.HR]: () => <hr className="border-gray-darker mb-6 mt-4" />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
  },
};

export const RICH_TEXT_RENDER_OPTIONS_HOW_WE_GOT_THERE = {
  ...RICH_TEXT_RENDER_OPTIONS,
  renderNode: {
    ...RICH_TEXT_RENDER_OPTIONS.renderNode,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="flex pb-5 md:pb-6 lg:pb-7 last:pb-0 max-w-lg">
        <CaretDownIcon className="flex-shrink-0 text-purple fill-current mt-1 ml-0.5 md:transform md:-translate-x-px" />
        <span className="pl-4 md:pl-6">{children}</span>
      </li>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <div className="flex">
        <CaretRightIcon className="flex-shrink-0 text-blue fill-current mt-1.5 ml-1" />
        <h3 className="text-2xl md:text-3xl lg:font-5xl pl-4 leading-relaxed font-500">
          {children}
        </h3>
      </div>
    ),
  },
};

export const RICH_TEXT_RENDER_OPTIONS_THE_REAL_VALUE = {
  ...RICH_TEXT_RENDER_OPTIONS,
  renderNode: {
    ...RICH_TEXT_RENDER_OPTIONS.renderNode,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="flex pb-5 md:pb-6 lg:pb-7 last:pb-0 max-w-lg">
        <CaretRightIcon className="flex-shrink-0 text-blue fill-current mt-1.5 ml-1" />
        <span className="pl-4 md:pl-6 -ml-1">{children}</span>
      </li>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <div className="flex">
        <CaretRightIcon className="flex-shrink-0 text-blue fill-current mt-1.5 ml-1" />
        <h3 className="text-2xl md:text-3xl lg:font-5xl pl-4 leading-relaxed font-500">
          {children}
        </h3>
      </div>
    ),
  },
};

export const RICH_TEXT_RENDER_OPTIONS_PROJECT = {
  ...RICH_TEXT_RENDER_OPTIONS,
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="text-white">{text}</span>,
  },
  renderNode: {
    ...RICH_TEXT_RENDER_OPTIONS.renderNode,
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="pb-4 xl:text-lg">{children}</p>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="text-purple-light font-600 pb-6">{children}</h6>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-white text-2xl md:text-3xl lg:text-5xl pl-4 leading-snug font-500">
        {children}
      </h3>
    ),
  },
};
